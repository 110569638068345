  
<template>
  <div>
    <div class="mt-5 mb-2 px-3">
      <template v-if="section && section.egress_profile_matter != null">
        <h4>
          Información de la
          {{
            $getVisibleNames("mesh.egressprofilematter", false, "Asignatura")
          }}
        </h4>
        <NewMatterPresentation
          class="mt-3"
          v-if="egress_profile_matter && matter"
          :Matter="egress_profile_matter"
          :oldMatter="matter"
        ></NewMatterPresentation>
      </template>
      <template v-else-if="section && section.matter != null">
        <h4>
          Información de la
          {{ $getVisibleNames("teaching.matter", false, "Asignatura Base") }}
        </h4>
        <NewMatterPresentation
          class="mt-3"
          v-if="matter"
          :oldMatter="matter"
        ></NewMatterPresentation>
      </template>
    </div>
    <template v-if="egress_profile_matter">
      <h4>
        Información de la
        {{ $getVisibleNames("teaching.section", false, "Sección") }}
      </h4>
      <HeaderSection
        class="pt-1 pb-4"
        :Matter="egress_profile_matter"
        :section_id="section_id"
        :allows_crud="false"
      ></HeaderSection
    ></template>
    <h3>Assessment</h3>
    <template>
      <AssessmentStudentTable
        class="px-2 pt-2"
        v-if="user && matter"
        :section_id="section_id"
        :matter="matter"
        :student_selected="user.id"
        :hidden_assessment_formative="hidden_assessment_formative"
        :hidden_assessment_sumative="hidden_assessment_sumative"
      ></AssessmentStudentTable>
    </template>
    <NewDashboardCustomFab
      v-if="section_id"
      :section_id="section_id"
      :show_assessment_student="false"
      :show_student_views="true"
    ></NewDashboardCustomFab>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "AssessmentStudentView",
  components: {
    NewMatterPresentation: () =>
      import(
        "@/components/teaching/MatterProgram/Components/NewMatterPresentation.vue"
      ),
    NewDashboardCustomFab: () =>
      import("@/components/dashboard/NewDashboardCustomFab"),
    AssessmentStudentTable: () =>
      import("@/components/reports/TeachingReport/AssessmentStudentTable"),
    HeaderSection: () => import("@/components/teaching/Section/HeaderSection"),
  },
  props: {},
  data() {
    return {
      section_id: Number(this.$route.params.section_id),
      allows_crud: false,
      hidden_assessment_formative: false,
      hidden_assessment_sumative: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      sections: names.SECTIONS,
      egress_profile_matters: names.EGRESS_PROFILES_MATTERS,
      matters: names.MATTERS,
      // evaluations: names.NEW_EVALUATIONS,
      // tests: names.NEW_TESTS,
    }),
    section() {
      return this.sections.find((x) => x.id == this.section_id);
    },
    egress_profile_matter() {
      if (this.section && this.section.egress_profile_matter != null)
        return this.egress_profile_matters.find(
          (x) => x.id == this.section.egress_profile_matter
        );
      else return null;
    },
    matter() {
      if (this.section && this.section.matter != null)
        return this.matters.find((x) => x.id == this.section.matter);
      else if (
        this.egress_profile_matter &&
        this.egress_profile_matter.matter != null
      )
        return this.matters.find(
          (x) => x.id == this.egress_profile_matter.matter
        );
      else return null;
    },
    // evaluationsFormative() {
    //   return this.evaluations.filter(
    //     (x) =>
    //       x.scope == 1 &&
    //       x.scope_id == this.section_id &&
    //       [2, 3].includes(x.type)
    //   );
    // },
    // evaluationsSumative() {
    //   return this.evaluations.filter(
    //     (x) =>
    //       x.scope == 1 &&
    //       x.scope_id == this.section_id &&
    //       [1, 4].includes(x.type)
    //   );
    // },
    // testsSumative() {
    //   const evaluations_ids = this.evaluationsSumative.map((x) => x.id);
    //   return this.tests.filter((x) => evaluations_ids.includes(x.evaluation));
    // },
    // testsFormative() {
    //   const evaluations_ids = this.evaluationsFormative.map((x) => x.id);
    //   return this.tests.filter((x) => evaluations_ids.includes(x.evaluation));
    // },
    // current_test_sumative() {
    //   const currentDate = this.$moment();
    //   return this.testsSumative.some((x) => {
    //     const startTime = this.$moment(x.start_time);
    //     const endTime = this.$moment(x.end_time);
    //     return currentDate.isBetween(startTime, endTime, null, "[]");
    //   });
    // },
    // current_test_formative() {
    //   const currentDate = this.$moment();
    //   return this.testsFormative.some((x) => {
    //     const startTime = this.$moment(x.start_time);
    //     const endTime = this.$moment(x.end_time);
    //     return currentDate.isBetween(startTime, endTime, null, "[]");
    //   });
    // },
  },
  methods: {
    pushDashboard() {
      this.$swal
        .fire({
          backdrop: `rgba(0,0,0,0.99)`,
          title: "Acceso Denegado",
          text: "No tienes permiso para ver la siguiente vista",
          type: "warning",
          showConfirmButton: false,
          timer: 5000, // Tiempo del temporizador en milisegundos (5000 ms = 5 segundos)
          timerProgressBar: true,
          willClose: () => {
            this.$router.push({ name: "DashboardView" });
          },
        })
        .then(() => {
          this.$router.push({ name: "DashboardView" });
        });
    },
    showAssessmentStudent(evaluation_ids) {
      if (evaluation_ids.length > 0)
        this.$restful
          .Post(`/evaluations2/show_assessment_student/`, {
            evaluation_ids: evaluation_ids,
          })
          .then((response) => {
            this.hidden_assessment_formative =
              response.hidden_assessment_formative;
            this.hidden_assessment_sumative =
              response.hidden_assessment_sumative;
          });
    },
  },
  watch: {
    section(value) {
      if (
        value != null &&
        this.user &&
        !value.students.includes(this.user.id)
      ) {
        this.pushDashboard();
      }
    },
  },
  created() {
    this.$store
      .dispatch(names.FETCH_NEW_EVALUATIONS, {
        scoped_id: 1,
        scoped_object_id: this.section_id,
      })
      .then((response) => {
        const evaluations_ids = response.map((x) => x.id);
        this.showAssessmentStudent(evaluations_ids);
        this.$store.dispatch(names.FETCH_NEW_TESTS, {
          evaluations_ids: evaluations_ids,
        });
      });
    this.$store
      .dispatch(names.FETCH_SECTION, this.section_id)
      .then((section) => {
        if (section.egress_profile_matter != null) {
          this.$store
            .dispatch(
              names.FETCH_EGRESS_PROFILE_MATTER,
              section.egress_profile_matter
            )
            .then((egress_profile_matter) => {
              this.$store.dispatch(
                names.FETCH_MATTER,
                egress_profile_matter.matter
              );
            });
        } else if (section.matter != null) {
          this.$store.dispatch(names.FETCH_MATTER, section.matter);
        }
      });
  },
};
</script>

<style scoped>
.title-header {
  padding: 0 2rem;
}
</style>

